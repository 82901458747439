<template>
  <div
    class="uk-container uk-container-expand bg-dark uk-padding-small"
  >
    <div class="uk-width-1-1@m uk-width-1-1@s uk-align-center">

      <div class="uk-flex uk-flex-wrap uk-container uk-padding-small@s">
        <div
          class="uk-width-expand@m uk-flex-center user-card"
          v-for="(user, i) in users"
          :key="i"
        >
          <div class="uk-card uk-width-auto uk-margin-remove">
            <div class="uk-width-1-1 ">
              <img
                class="uk-border-circle uk-align-center uk-padding-large-right"
                :src="user.photo"
              />
            </div>
          </div>

          <div
            class="uk-card uk-width-auto uk-align-center uk-margin-remove contacts uk-text-center"
          >
            <p class="name uk-margin-small">{{ user.name }}</p>
            <p class="work uk-margin-small">{{ user.position }}</p>
            <p
              class="uk-margin-small uk-text-bold"
              v-for="(number, i) in user.numbers"
              :key="i"
            >
              <span
                class="uk-margin-small-right green"
                uk-icon="receiver"
              ></span
              ><a :href="`tel: ${number.number}`">{{ number.number }}</a> 
              <template v-if="number.position"
                > ({{ number.position }})</template
              >
            </p>
            <div class="uk-margin-small-top uk-text-bold">
              <span class="uk-margin-small-right green" uk-icon="mail"></span
              ><a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [
        {
          name: "Екатерина Портнягина",
          position: "Специалист по работе с партнерами",
          numbers: [
            { number: "+7 (922) 077-25-65" },
          ],
          email: "portnyagina.e@vevanta.ru",
          photo: "photos/ekaterina.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media screen and (max-width: 640px) {
  .user-card:not(:first-child) {
    margin-top: 20px;
  }
}
</style>
