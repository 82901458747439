<template>
  <div id="app">
    <div class="uk-container uk-container-expand uk-padding-remove">
      <header-template></header-template>
      <consult-template></consult-template>
      <contacts-template></contacts-template>
      <footer-template></footer-template>
    </div>
  </div>
</template>

<script>
import headerTemplate from "./components/Header";
import footerTemplate from "./components/Footer";
import contactsTemplate from "./components/Contacts";
import consultTemplate from "./components/ConsultModal";

export default {
  name: "App",
  components: {
    headerTemplate,
    footerTemplate,
    contactsTemplate,
    consultTemplate,
  },
};
</script>

<style>
@import 'assets/fonts/gilroy.css';
*, h1, h2, h3, h4, h5, p, a {
  font-family: gilroy, sans-serif;
}

.bg-dark {
  background-color: #f5f5f5;
}

.green,
.green:hover {
  color: #78beb5;
}
.green-button {
  background-color:  #78beb5;
  color: #fff;
  font-weight: bold;
}

.radius-button {
  border-radius: 30px;
}

.uk-input,
.uk-input:focus {
  border-color: #78beb5;
}

.uk-form-danger {
  border-color: #f0506e !important;
}

</style>
