<template>
  <div class="uk-container">
    <nav class="uk-navbar uk-navbar-container uk-hidden@m uk-padding-small">
      <div class="uk-navbar-left">
        <a href="https://vevanta.com"
          ><img src="../assets/img/logo.png" width="150px"
        /></a>
      </div>
      <div class="uk-navbar-right">
          <div>
            <span class="uk-margin-small-right green" uk-icon="receiver"></span
            ><a class="green" href="tel:+73452396571"
              >+7 (3452) 39-65-71</a
            >
          </div>
      </div>
    </nav>

    <div class="uk-visible@m">
      <div class="uk-margin-medium-top"></div>
      <div class="uk-container uk-margin-remove" uk-grid>
        <div class="uk-width-expand uk-padding-remove">
          <a href="https://vevanta.com"><img src="../assets/img/logo.png"/></a>
        </div>
        <div
          class="uk-width-expand uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6"
        >
          <p class="uk-margin-remove">г. Тюмень,</p>
          <p class="uk-margin-remove">ул. Червишевский тракт, д. 3</p>
        </div>
        <div
          class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6"
        >
          <p class="uk-margin-remove">Режим работы:</p>
          <p class="uk-margin-remove">Пн-Сб 09:00-19:00</p>
          <p class="uk-margin-remove">
            <a class="green uk-text-bold" href="tel:+73452395671"
              >+7 (3452) 39-65-71</a
            >
          </p>
        </div>
        <div
          class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6 logos"
        >
          <p class="uk-margin-small">
            <a
              href="https://vk.com/stroitelstvo_domov_i_kottedjey"
              target="_blank"
              ><img src="../assets/img/vkontakte.png" height="20px" width="20px"
            /></a>
          </p>
          <p class="uk-margin-small">
            <a href="https://www.instagram.com/vevanta72" target="_blank"
              ><img src="../assets/img/instagram.png" height="20px" width="20px"
            /></a>
          </p>
          <p class="uk-margin-small">
            <a
              href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
              target="_blank"
              ><img src="../assets/img/youtube.png" height="20px" width="20px"
            /></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.uk-subnav li a {
  font-size: 0.75em !important;
}
.uk-subnav > * > :first-child {
  color: #000 !important;
}
.nav-top {
  padding-right: 0 !important;
}

.nav-top a {
  font-size: 11px;
}

.uk-navbar-nav.nav-bottom li:first-child a {
  padding-left: 0 !important;
}

.uk-navbar-nav.nav-bottom a {
  font-weight: bold;
}

.logos a {
  opacity: 0.9;
  filter: grayscale(1);
  transition: filter 0.3s, opacity 0.3s;
}

#mobile-nav p,
#mobile-nav li,
#mobile-nav a,
hr {
  color: #666;
  border-color: #666;
}
</style>
